import { FC, useEffect, useRef } from 'react';

import { useRouter } from '@dxp-next';
import { UsabillaFeedbackRendering } from '@sitecore/types/manual/UsabillaFeedback';

const UsabillaFeedback: FC<UsabillaFeedbackRendering> = ({ fields }) => {
  const element = useRef<HTMLDivElement>(null);

  const router = useRouter();

  let experienceEditorText = '';
  if (fields.inPageSurveyIdText?.editable) {
    experienceEditorText = 'placeholder for usabilla component';
  }

  //Make sure only 1 instance rendered
  useEffect(() => {
    const ubElement = element.current;

    if (!ubElement || typeof window === 'undefined') return;

    // Clear the element on route change, this is essential for in page navigation where the element is not re-rendered
    router.events.on('routeChangeStart', () => {
      if (ubElement?.childNodes?.length) {
        while (ubElement.firstChild) {
          ubElement.removeChild(ubElement.firstChild);
        }
      }
    });
  }, [router]);

  useEffect(() => {
    const ubElement = element.current;

    if (!ubElement) return;

    /**
     * Usabilla traverses the parents of the `ub-in-page` element and checks
     * their `getBoundingClientRect` dimensions to set the dimensions of its
     * iframe. As elements with `display: contents` return dimensions with
     * value `0`, the iframe is displayed with the same dimensions.
     *
     * By overwriting the `getBoundingClientRect` method of these elements with
     * their parent's (which is exactly what they display as), this Usabilla
     * issue is fixed.
     */
    let parent = ubElement.parentElement;
    while (parent && parent.parentElement) {
      if (window.getComputedStyle(parent)?.display === 'contents') {
        parent.getBoundingClientRect = function (parent: HTMLElement) {
          // @ts-ignore: parent.parentElement is already checked to exist in while loop
          return parent.parentElement.getBoundingClientRect();
        }.bind(this, parent);
      }
      parent = parent.parentElement;
    }
  }, [element]);

  /* eslint-disable react/no-unknown-property */
  return (
    <div
      ub-in-page={fields.inPageSurveyIdText?.value}
      ub-in-page-category={fields.inPageCategoryText?.value}
      ub-in-page-subcategory={fields.inPageSubCategoryText?.value}
      ub-in-page-item={fields.inPageSurveyItemText?.value}
      ref={element}>
      {experienceEditorText}
    </div>
  );
  /* eslint-enable react/no-unknown-property */
};

export default UsabillaFeedback;
